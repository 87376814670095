<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
          فتح القائمة
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-left flex-grow-1 d-xl-none d-flex"
    >
      <div v-if="!teacher" class="col-12 text-right">
         <b-dropdown dropright no-caret 
              id="dropdown-1" style="border-radius: 0px"
              html="<i class='fa fa-cogs'></i>"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="outline-secondary"
          >
              <b-dropdown-item @click="$router.go(-1)">
                <i class="fa fa-arrow-right"></i> الصفحة السابقة
              </b-dropdown-item>
              <b-dropdown-item @click="$router.push('/'); reload()">
                <i class="fa fa-home"></i> الرئيسية
              </b-dropdown-item>
              <b-dropdown-item @click="fav()" v-if="!infav && viewsmenu">
                <i class="fa fa-star"></i> حفظ كإختصار
              </b-dropdown-item>
              <b-dropdown-item @click="fav()" class="text-danger" v-if="infav && viewsmenu">
               <span class="text-danger" style="color: darkred !important"><i class="fa fa-star"></i> ازالة الاختصار</span>
              </b-dropdown-item>
          </b-dropdown>
      </div>
     </div>
    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <div v-if="!teacher">
         <a @click="$router.go(-1)" class="hoe"><i class="fa fa-arrow-right"></i> الصفحة السابقة</a>
          |
          <a @click="$router.push('/'); reload()" class="hoe"><i class="fa fa-home"></i> الرئيسية</a>
          |
          <a @click="fav()" v-if="!infav && viewsmenu" class="hoe"><i class="fa fa-star"></i> حفظ الصفحة كإختصار</a>
          <a @click="fav()" v-if="infav && viewsmenu" class="hoe text-success"><i class="fa fa-star"></i> ازالة الصفحة من الاختصارات</a>
      </div>
      <div style=" margin: 0px auto;">
        <img :src="logo" style="width: 40px; height: 40px; object-fit: cover; border-radius: 4px !important">
        <span style="font-size: 20px"> &nbsp;{{ user.name }}
          <template v-if="user.subuser">
            <small class="text-muted">
              {{ user.admin.name }}
            </small>
          </template>
        </span>
      </div>
     </div>

  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdown,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";

import Ripple from "vue-ripple-directive";
export default {
  directives: {
    Ripple
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      logo: require('@/assets/images/school.png'),
      teacher: false,
      infav: false,
      last_url: window.location.href,
      viewsmenu: window.location.href.includes("_students") ? true : false
    };
  },
  created() {
    var g = this;
    if(g.user?.settings?.logo){
      g.logo = g.user?.settings?.logo
    }
    if(localStorage.getItem("teacher") && window.location.href.includes("_xteacher")){
      g.logo = require('@/assets/images/avatar.png')
      g.user = JSON.parse(localStorage.getItem("teacher"))
      g.teacher = true
    }
    if($(".router-link-exact-active:visible")?.first()?.text()){
      $("title").first().html('التحضير الذكي &mdash; ' + $(".router-link-exact-active:visible")?.first()?.text())
    }
    setTimeout(function(){
      if($(".router-link-exact-active:visible")?.first()?.text()){
        $("title").first().html('التحضير الذكي &mdash; ' + $(".router-link-exact-active:visible")?.first()?.text())
      }
    }, 1000)
    setInterval(function(){
      if(window.location.href != g.last_url){
        g.last_url = window.location.href;
        if($(".router-link-exact-active:visible")?.first()?.text()){
          $("title").first().html('التحضير الذكي &mdash; ' + $(".router-link-exact-active:visible")?.first()?.text())
        }else{
          $("title").first().html('التحضير الذكي')
        }
      }
      if(!localStorage.getItem('favs')){
        localStorage.setItem('favs', '[]')
      }
      var favs = JSON.parse(typeof localStorage.getItem('favs') == 'string' && localStorage.getItem('favs') != 'undefined' ? localStorage.getItem('favs') : '[]')
      g.infav = false;
      favs.forEach(function(a){
        if(a.url == window.location.pathname){
          g.infav = true;
        }
      })
    }, 100)
  },
  methods: {
    fav(){
      var g = this;
      if(g.infav){
        var arr = [];
        var favs = JSON.parse(typeof localStorage.getItem('favs') == 'string' && localStorage.getItem('favs') != 'undefined' ? localStorage.getItem('favs') : '[]')
        favs.forEach(function(a){
          if(a.url != window.location.pathname){
            arr.push(a)
          }
        })
        localStorage.setItem("favs", JSON.stringify(arr))
        g.infav = false;
      }else{
        var favs = JSON.parse(typeof localStorage.getItem('favs') == 'string' && localStorage.getItem('favs') != 'undefined' ? localStorage.getItem('favs') : '[]')
        favs.push({
          title: prompt("العنوان", $(".router-link-exact-active:visible")?.first()?.text()) ?? $(".router-link-exact-active:visible")?.first()?.text(),
          url: window.location.pathname
        })
        localStorage.setItem("favs", JSON.stringify(favs))
        g.infav = true;
      }
    },
    reload(){
      setTimeout(function(){
        location.reload()
      }, 50)
    }
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdown,
    BDropdownDivider,
    BAvatar,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style>
.hoe:hover{
  color: green !important;
}
@media (max-width: 767.98px){
  [dir=rtl] .header-navbar .navbar-container .show .dropdown-menu  {
    min-width: 250px !important;
      right: -200px !important;
      left: 222px
  }
}
</style>